import React, { useEffect, useState } from 'react';
import Header83 from '../components/Header83';
import Button83 from '../components/Button83';
import Footer83 from '../components/Footer83';

function ErrorPage() {
  return (
    <React.Fragment>
      <Header83 />
      <div className='content-body83 bg-white'>
        <div className='error-page'>
          <div className='error-content'>
            <div className=''>
              <img src={require('../assets/images/404.gif')} />
            </div>
            <p>WE ARE SORRY, BUT THE PAGE YOU REQUESTED
              WAS NOT FOUND.</p>

            <Button83 className='button83-outlined-primary' label='Back' />
            {/* <p>
          <i>{error.statusText || error.message}</i>
        </p> */}
          </div>
        </div>
      </div>
      <Footer83 />
    </React.Fragment>
  );
}

export default ErrorPage;