import React from 'react';
import Select from "react-select";
import BreadCrumbs83 from '../../components/BreadCrumbs83';
import Button83 from '../../components/Button83';
import Card83 from '../../components/Card83'
import Form83 from '../../components/Form83';
import Header83 from '../../components/Header83';
import { ActionTecForm } from './ActionTecForm';

function ActionTec() {
    const cardData = {
        icon: "fas fa-chevron-left",
        title: "ActionTec",
    }

    return (
        <React.Fragment>
            <Header83 />
            <BreadCrumbs83 />
            <div className="content-body83">
                <div className='d-flex-row'>
                    <div className='flex-25 padding-x-12'>
                        <Form83 formFields={ActionTecForm} />
                    </div>

                    <div className='flex-75 padding-x-12'>
                        <Card83 cardData={cardData} className="hide-header" childComponents={() => <Button83 className="button83-standard"
                            label="ActionTec" icon="far fa-chevron-left"
                        />
                        }>
                            <div className='nested-card83'>
                                <div className='nested-card83-header'>
                                    <h6>Summary</h6>
                                </div>

                                <div className='nested-card83-body'>
                                    <div className='display-flex flex-wrap align-items-center justify-content-between'>
                                        <div className='flex-33 margin-bottom-30'>
                                            <h6>Customer Name</h6>
                                            <p>ActionTec</p>
                                        </div>

                                        <div className='flex-33 margin-bottom-30'>
                                            <h6>Account Name</h6>
                                            <p>Windstream</p>
                                        </div>

                                        <div className='flex-33 margin-bottom-30'>
                                            <h6>Environment</h6>
                                            <p>Production</p>
                                        </div>

                                        <div className='flex-33'>
                                            <h6>Created At</h6>
                                            <p>01/01/23</p>
                                        </div>

                                        <div className='flex-33'>
                                            <h6>Created By</h6>
                                            <p>Kuldeep Singh</p>
                                        </div>

                                        <div className='flex-33'>
                                            <h6>Expiry</h6>
                                            <Select className="form-control-multi-select custom-react-box" classnameprefixix="form-control-multi-select" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className='display-flex flex-wrap align-items-center justify-content-between keys-list'>
                                <li>
                                    <h6>Access Key</h6>
                                    <p className='text-secondary align-items-center'>
                                        <Button83 className="button83-standard" icon="far fa-copy" />
                                        abdsq274w5r8qwf823
                                    </p>
                                </li>

                                <li>
                                    <h6>Expiring in</h6>
                                    <p className='text-danger'>2 Days</p>
                                </li>

                                <li>
                                    <button className='button83 button83-standard text-danger'>
                                        Delete Key <i className="fas fa-chevron-right margin-left-7"></i>
                                    </button>
                                </li>
                            </ul>

                            <div className='display-flex align-items-center justify-content-end'>
                                <Button83 className=' button83-primary margin-right-15' label="Regenerate Key" />

                                <Button83 className=' button83-outlined-danger' label="Deactivate Key" />

                            </div>
                        </Card83>

                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default ActionTec;