import React, { memo, useState, useEffect, useRef } from "react";
import { msalInstance } from "../../pages/Login/msalConfig";
import ClickAwayListener from "react-click-away-listener";
import { useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";
import Button83 from "../Button83";
import { useSelector } from "react-redux";

const convertedText = (inputText) => {
  return inputText.replace("flex83-", "")
    .split("-")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function UserProfile() {
  const userDetails = useSelector(state => state.apiResponse?.userDetails);
  const profilePic = useSelector(state => state.apiResponse?.profilePic);
  const isAuthenticated = useIsAuthenticated();
  const [jobTitle, setJobTitle] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const currentAccount = msalInstance.getAllAccounts()[0];
  const [openDropdown, setOpenDropdown] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [role, setRole] = useState('')
  const [closeMenu, setCloseMenu] = useState(false)
  const sidebarRef = useRef(null)

  useEffect(() => {
    if (isAuthenticated) {
      const accessToken = localStorage.getItem('token');
      if (accessToken) {
        const headers = {
          'Authorization': accessToken,
          "Content-Type": "application/json"
        };

        // Retrieve user profile data
        axios.get('https://graph.microsoft.com/v1.0/me', { headers })
          .then((response) => {
            const { mobilePhone, jobTitle } = response.data;
            setMobilePhone(mobilePhone);
            setJobTitle(jobTitle);
          })
          .catch((error) => {
            console.log('Error fetching user data:', error);
          });

        // Retrieve user profile picture
        axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          responseType: 'arraybuffer',
          headers
        })
          .then((response) => {
            const base64Image = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            setProfilePicture(`data:image/jpeg;base64, ${base64Image}`);
          })
          .catch((error) => {
            console.log('Error fetching user profile picture:', error);
          });

        axios.get(`${window.APP_CONFIG.API_BASE_URL}flex83/account/api/v6/permissions`, { headers })
          .then((response) => {
            setRole(convertedText(response?.data?.data?.permissions[0]))


          })
          .catch((error) => {
            console.log('Error fetching user profile picture:', error);
          });
      }
    }
  }, [isAuthenticated]);

  const openDropdownHandler = () => {
    setOpenDropdown(prev => !prev);
  };

  const handleClickAway = () => {
    setOpenDropdown(prev => !prev)
    setCloseMenu(true)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (openDropdown && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Clicked outside of the sidebar, close it here
        setOpenDropdown(false)
        setCloseMenu(true)
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openDropdown]);

  return (
    <div className="display-flex align-items-center">
      <div className="user-profile">
        <div className="img-wrapper" onClick={openDropdownHandler}>
          <img src={(profilePic || profilePicture) ?? require('../../assets/images/user.png')} className="img-responsive" alt="Profile" />
        </div>
        <div className="dropdown83">
          <div className={openDropdown ? 'dropdown-menu83 sliding-dropdown83-show' : "dropdown-menu83 " + (closeMenu ? "sliding-dropdown83-hide" : "")} ref={sidebarRef}>
            <Button83 className='button83-danger-icon' onClick={handleClickAway} icon='far fa-times' />

            <div className="dropdown-item83">
              <div className="user-profile-img">
                <img src={(profilePic || profilePicture) ?? require('../../assets/images/user.png')} />
              </div>
            </div>

            <div className="dropdown-item83">
              <h6><i className="far fa-medal"></i>Designation :</h6>
              <span>{userDetails?.jobTitle || jobTitle || 'N/A'}</span>
            </div>
            <div className="dropdown-item83">
              <h6><i className="fal fa-envelope"></i> Email :</h6>
              <span>{currentAccount?.username || 'N/A'}</span>
            </div>
            <div className="dropdown-item83">
              <h6><i className="far fa-phone"></i> Contact No. :</h6>
              <span>{userDetails?.mobilePhone || mobilePhone || 'N/A'}</span>
            </div>

            {/* <div className="dropdown-item83">
                <h6><i className="far fa-bell"></i> Notifications :</h6>
                <span className="badge">+999</span>
              </div> */}
          </div>
        </div>

      </div>

      <div className="user-info">
        <h6>{currentAccount?.name}</h6>
        <p>{role}</p>
      </div>
    </div>
  );
}

export default UserProfile;
