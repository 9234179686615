import { apiSlice } from "../store/apiSlice"

const accounts = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllAccounts: builder.query({
            query: (params) => ({
                url: `/flex83/account/api/v6`,
                params
            })
        }),
        getAccountById: builder.mutation({
            query: (id) => `/flex83/account/api/v6/${id}`
        }),
        createAccount: builder.mutation({
            query: (payload) => ({
                url: `/flex83/account/api/v6`,
                method: "POST",
                body: payload
            })
        }),
        deleteAccount: builder.mutation({
            query: (id) => ({
                url: `/flex83/account/api/v6/${id}`,
                method: "DELETE",
            })
        }),
        updateAccount: builder.mutation({
            query: ({ payload, id }) => {
                return ({
                    url: `/flex83/account/api/v6/${id}`,
                    method: "PUT",
                    body: payload
                })
            }
        }),
        updateStatus: builder.mutation({
            query: ({ params, id }) => {
                return ({
                    url: `/flex83/account/api/v6/${id}/state`,
                    method: "PUT",
                    params
                })
            }
        }),
        generateSecretKey: builder.mutation({
            query: ({ payload, accountId }) => {
                return ({
                    url: `/flex83/account/api/v6/${accountId}/secret`,
                    method: "POST",
                    body: payload
                })

            }
        }),
        getEnvironmentList: builder.query({
            query: () => {
                return ({
                    url: '/flex83/account/api/v6/enviornment',
                })
            }
        }),
        suspendAccount: builder.mutation({
            query: (accountId) => ({
                url: `/flex83/account/api/v6/${accountId}/suspend`,
                method: 'PATCH'
            })
        }),
        rotateKey: builder.mutation({
            query: ({ payload, accountId }) => {
                return ({
                    url: `/flex83/account/api/v6/${payload.accountId}/rotateSecret`,
                    body: payload,
                    method: 'PATCH'
                })
            }

        }),
        deleteSecretKey: builder.mutation({
            query: (accountId) => {
                return ({
                    url: `/flex83/account/api/v6/${accountId}/secret`,
                    body: { accountId },
                    method: 'DELETE'

                })
            }

        }),
        renewAccount: builder.mutation({
            query: ({ payload, accountId }) => {
                return ({
                    url: `/flex83/account/api/v6/${payload.accountId}/renew`,
                    body: payload,
                    method: 'POST'

                })
            }


        }),
    })
})

export const { useGetAllAccountsQuery, useGetAccountByIdMutation, useCreateAccountMutation, useDeleteAccountMutation, useUpdateAccountMutation, useUpdateStatusMutation, useGenerateSecretKeyMutation, useGetEnvironmentListQuery, useSuspendAccountMutation, useRotateKeyMutation, useDeleteSecretKeyMutation, useRenewAccountMutation } = accounts
export const { endpoints: {
    getAllAccounts
} } = accounts