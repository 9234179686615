import React from 'react';
// import Button83 from "../Button83";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Button83 from '../../components/Button83';
import Login from '../../components/Login';

function LoginPage() {

  return (
    <React.Fragment>
      <div className='login-wrapper'>
        <div className='display-flex align-items-center flex-wrap w-100'>
          <div className='flex-70'>
            <div className='login-img'>
              <img src={require('../../assets/images/loginBg.png')}/>

              <div className='login-taglines'>
                <div className='justify-content-center display-flex align-items-center'>
                <h1>Welcome to</h1> <img src={require('../../assets/images/flex-logo.png')} className="logo" />
                </div>
                <h6>Application Enablement Platform Accelerating Industrial IoT Application Developments.</h6>
                <Login/>
              </div>
              
            </div>
          </div>

          <div className='flex-30'>
            <div className='bg-white'>
              <div className='login-info'></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginPage;