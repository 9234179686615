import React, { useEffect, useState, useRef, } from 'react'
import Button83 from '../Button83';
import Search83 from '../Search83';
import Dropdown83 from '../Dropdown83';
import TableSkeleton from './TableSkeleton'

function Table83({ tableTitle, paginationIcons, sortingIcons, isPagination = true, defaultPageSize = 10, hideGoToPage = false, hidePageSize = false, isCellsEditable = false, isFiltering = true, isGroupBy = false, isSortBy = true, isMultiSort = false, isSelectable = true, CSRData, SSRData, headers, searchFilterPlaceholder = 'Search...', isDebounce = false, debounceDuration = 1000, isSSR = false, onFilteredChange, onSortByChange, onPageChange, pageCount, onChangeHideColumns, manualHiddenColumns, hideableColumns = false, preventFirstCall = false, onRowClick, totalRecords, loading, NoDataFoundComponent, LoadingComponent, onRowSelection, initialState }) {

  const [data, setData] = useState(isSSR ? SSRData : CSRData)
  const [autoHeaders, setAutoHeaders] = useState([])
  const autoHeadersRef = useRef(false);

  const updateMyData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  useEffect(() => {
    function generateHeaders(columns) {
      let keys = Object.keys(columns);
      keys.forEach((key) => {
        const result = key.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        setAutoHeaders((prev) => [...prev, {
          Header: finalResult,
          accessor: key,
        }]);
      })
    }
    if (!headers && data.length > 0) {
      if (autoHeadersRef.current === false) {
        generateHeaders(data[0]);
        autoHeadersRef.current = true;
      }
      else {
        setAutoHeaders([]);
        generateHeaders(data[0]);
        autoHeadersRef.current = true;
      }

    }
  }, [data])

  useEffect(() => {
    setData(isSSR ? SSRData : CSRData)
  }, [SSRData, CSRData])
  return (
    <React.Fragment>
      <div className="table83-wrapper">
        <div className="table83">
          <div className="table83-header align-items-center justify-content-between ">
            {tableTitle && <h6 className="table83-title">{tableTitle}</h6>}

            {/* <div className='table83-header-buttons-group align-items-center justify-content-between'>
              <Search83 />
              <Dropdown83 iconClassName="fal fa-filter" className="button83-default" label="Filter">
                <div className='dropdown-item83'>
                </div>

                <div className='dropdown-item83'>
                </div>

                <div className='dropdown-item83'>
                </div>
              </Dropdown83>
            </div> */}
          </div>

          <TableSkeleton
            columns={!headers ? autoHeaders : headers}
            data={data}
            loading={loading}
            paginationIcons={paginationIcons}
            sortingIcons={sortingIcons}
            pageCount={pageCount}
            updateMyData={updateMyData}
            isPagination={isPagination}
            defaultPageSize={defaultPageSize}
            hideGoToPage={hideGoToPage}
            hidePageSize={hidePageSize}
            isCellsEditable={isCellsEditable}
            isFiltering={isFiltering}
            isGroupBy={isGroupBy}
            isSortBy={isSortBy}
            isMultiSort={isMultiSort}
            isSelectable={isSelectable}
            searchFilterPlaceholder={searchFilterPlaceholder}
            isDebounce={isDebounce}
            debounceDuration={debounceDuration}
            isSSR={isSSR}
            onFilteredChange={onFilteredChange}
            onSortByChange={onSortByChange}
            onPageChange={onPageChange}
            onChangeHideColumns={onChangeHideColumns}
            manualHiddenColumns={manualHiddenColumns}
            hideableColumns={hideableColumns}
            preventFirstCall={preventFirstCall}
            onRowClick={onRowClick}
            totalRecords={totalRecords}
            NoDataFoundComponent={NoDataFoundComponent}
            LoadingComponent={LoadingComponent}
            onRowSelection={onRowSelection}
            initialState={initialState}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Table83;