import React, { useEffect, useState } from 'react';
import Form83 from '../../components/Form83';
import Header83 from '../../components/Header83';
import Table83 from '../../components/Table83';
import { CustomersForm } from './CustomersForm';
import { useCreateAccountMutation, useDeleteAccountMutation, useUpdateAccountMutation, useUpdateStatusMutation, getAllAccounts, useGetAccountByIdMutation, useGenerateSecretKeyMutation, useSuspendAccountMutation, useRotateKeyMutation, useDeleteSecretKeyMutation, useRenewAccountMutation } from '../../apis/accounts';
import { cloneDeep } from "lodash";
import Modal83 from '../../components/Modal83';
import { editCustomerForm } from './editCustomerForm';
import Loader83 from '../../components/Loader83';
import Button83 from '../../components/Button83';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import NoDataFound83 from '../../components/NoDataFound83';
import { timeStampDifference, convertTimestampDateTime, getTimeForward } from '../../store/commonUtils';
import Status83 from '../../components/Status83';
import Footer83 from '../../components/Footer83';
import { useSelector } from 'react-redux';

let initialFilterPayload = {
  sortBy: "createdAt",
  sortType: "DESC",
  offset: 0,
  max: 10
}

function Customers() {
  const apiResponse = useSelector(state => state.apiResponse?.customers);
  const [filterPayload, setFilterPayload] = useState(initialFilterPayload);
  // const { data, isSuccess, isLoading, isError, refetch } = useGetAllAccountsQuery(filterPayload)
  const [getAllData, { data, isSuccess, isLoading, isFetching, isError }] = getAllAccounts.useLazyQuery()
  const [createAccount] = useCreateAccountMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [updateStatus] = useUpdateStatusMutation();
  const [generateSecretKey] = useGenerateSecretKeyMutation()
  const [suspendAccount] = useSuspendAccountMutation()
  const [rotateKey] = useRotateKeyMutation()
  const [deleteSecretKey] = useDeleteSecretKeyMutation()
  const [renewAccount] = useRenewAccountMutation()
  const [getAccountById] = useGetAccountByIdMutation()
  const [accountsPayload, setAccountsPayload] = useState({})
  const { id, active, suspended, customerName, expiry, createdAt, createdBy, environment, planeSecret } = accountsPayload
  const [createFormPayload, setCreateFormPayload] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [editPayload, setEditPayload] = useState({});
  const [editFormPayload, setEditFormPayload] = useState();
  const [accountId, setAccountId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [activeModalPayload, setActiveModalPayload] = useState(null);
  const [accountName, setAccountName] = useState('')
  const [customerEmail, setCustomerEmail] = useState([]);
  const [isAccountModal, setIsAccountModal] = useState(false);
  const [newExpiryTime, setNewExpiryTime] = useState(null)
  const [isTextCopied, setIsTextCopied] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false)
  const [isModalLoader, setIsModalLoader] = useState(false)
  const [buttonLoader, setButtonLoader] = useState({
    generate: false,
    rotate: false,
    renew: false,
    delete: false,
    suspend: false
  })



  useEffect(() => { getAllData(filterPayload, false) }, [filterPayload])

  useEffect(() => {
    if (data) {
      let obj = data?.data?.response.map((items) => {
        return items;
      });
      setTableData(obj);
      setIsTableLoading(false);
    }
  }, [data])



  useEffect(() => {
    if (isSuccess) {
      let accName = data?.data?.response.map(items => items.accountName)
      let custEmail = data?.data?.response.map(items => items.customerEmail)
      setCustomerEmail(custEmail)
    }
    else if (isError && !data?.data?.response) {
      setIsTableLoading(false);
    }
  }, [isSuccess, isError])

  let editFormFields = CustomersForm.fields.slice(1)
  editCustomerForm.fields = editFormFields;

  const handleFormDataChange = (formData) => {
    setCreateFormPayload(formData)
  }

  const handleSubmit = () => {
    createAccount(createFormPayload).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        getAllData(filterPayload, false);
      }
    })

  }

  const handleEditPayloadChange = (payload) => {
    setAccountId(payload.id)
    setEditFormPayload(payload)
  }

  const editPayloadHandler = () => {
    let clone = cloneDeep(editFormPayload)
    updateAccount({ payload: clone, id: accountId }).then(res => {

      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        getAllData(filterPayload, false);
      }
    })

    setOpenModal(false)
  }

  const handleAccountsModal = (payload) => {
    setAccountsPayload(payload)
    setIsTextCopied(false)
    setIsAccountModal(true)
  }

  function expiryColorHandler(cell) {
    const { expiry } = cell.row.original
    if (expiry === 0) {
      return "text-secondary"
    } else if (timeStampDifference(expiry) === "Expired" || expiry < Date.now() + 604800000) {
      return "text-danger"
    } else {
      return "text-primary"
    }
  }


  const handleLicenseStatus = (active, suspended, expiry, key) => {
    if (active) {
      return key === "label" ? "Active" : "success"
    } else if (suspended) {
      return key === "label" ? "Suspended" : "exclamation"
    }
    else if (expiry && expiry < Date.now()) {
      return key === "label" ? "Expired" : "danger"
    } else {
      return key === "label" ? "Created" : "success"
    }
  }

  const cellHandler = ({ cell }) => {
    const { active, suspended, expiry } = cell.row.original
    switch (cell.column.id) {
      case ("accountName"):
        return (
          <div>
            <a onClick={() => handleAccountsModal(cell.row.original)} className='cursor-pointer'>{cell.row.original.accountName}</a>
          </div>
        )
      case ("Expiry"):
        return (
          <div>
            <h6 className={expiryColorHandler(cell)}>{cell.row.original.expiry === 0 ? "No key" : timeStampDifference(cell.row.original.expiry)}</h6>
          </div>
        )
      case ("License Status"):
        return (
          <div className=""
            onClick={() => checkboxClickHandler(cell.row.original)}
          >
            <h6>{cell.row.original.active}
              <Status83 statusType={handleLicenseStatus(active, suspended, expiry, "status")} label={handleLicenseStatus(active, suspended, expiry, "label")} type="tag" />
            </h6>

          </div>

        )
      case ("Action"):
        return (
          <div className="display-flex action-buttons-group justify-content-end padding-right-5">
            <Button83
              className='button83-green'
              variant='icon'
              icon='fal fa-pencil'
              dataTip="Edit"
              dataFor="edit"
              onClick={() => editUser(cell.row.original)}
            />
            <Button83
              className='button83-danger'
              variant='icon'
              icon='fal fa-trash-alt'
              dataTip="Delete"
              dataFor="delete"
              onClick={() => confirmDetails(cell.row.original)}
            />
          </div>

        )

    }
  }

  const checkboxClickHandler = (payload) => {
    setIsActiveModal(true)
    setAccountId(payload.id)
    setActiveModalPayload(!payload.active)
  }

  const onCancelModalHandler = () => {
    setIsActiveModal(false)
    setAccountId("")
    setActiveModalPayload(null)
  }

  const statusModalSubmitHandler = () => {
    setIsActiveModal(false)
    updateStatus({ params: { "active": activeModalPayload }, id: accountId }).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        getAllData(filterPayload, false);
      }
    })
  }

  const getColumns = () => {
    return [
      {
        Header: "Account Name",
        accessor: 'accountName',
        width: 150,
        Cell: cellHandler
      },
      {
        Header: 'Application',
        accessor: 'application',
        width: 120
      },
      {
        Header: "Customer Name",
        accessor: 'customerName',
        width: 120
      },
      {
        Header: "Environment",
        accessor: 'environment',
        width: 120
      },
      {
        Header: "Expiry",
        width: 120,
        Cell: cellHandler
      },
      {
        Header: "License Status",
        width: 120,
        Cell: cellHandler
      },
      {
        Header: "Action",
        width: 120,
        Cell: cellHandler
      },
    ]
  }

  const editUser = (obj) => {
    setAccountId(obj.id)
    let object = data?.data?.response.find(items => items.id === obj.id)
    setEditPayload(object)
    setOpenModal(true);
  }

  const confirmDetails = (obj) => {
    setAccountName(obj.accountName)
    setAccountId(obj.id)
    setIsDeleteModal(true)

  }

  const deleteModalHandler = () => {
    setButtonLoader(true)
    deleteAccount(accountId).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setButtonLoader(false)
        getAllData(filterPayload, false);
      } else {
        setButtonLoader(false)
      }
    })
    setIsDeleteButtonEnabled(false)
    setIsDeleteModal(false)
    setAccountId("")
  }

  const cancelButtonHandler = () => {
    setOpenModal(false)
    setAccountId("")
    setEditPayload({})
  }

  const deleteCancelButtonHandler = () => {
    setIsDeleteModal(false)
    setAccountId("")
  }

  const onFilteredChangeHandler = (payload) => {
    if (payload.length) {
      let result = {};
      for (const element of payload) {
        result[element.id] = element.value;
      }
      let object = { ...initialFilterPayload, ...result }
      setFilterPayload(object)
    } else if (!payload.length) {
      setFilterPayload(initialFilterPayload)
    }
  }

  const handlePagination = (data) => {
    setFilterPayload({
      max: data.pageSize,
      offset: data.pageIndex
    })
  }

  const handleExpirySelect = (event) => {
    let { value } = event.target
    let newExpiryTimeStamp = getTimeForward(value, expiry)
    setNewExpiryTime(newExpiryTimeStamp)
  }

  const handleKeyGeneration = () => {
    setButtonLoader({ ...buttonLoader, generate: true })
    generateSecretKey({ payload: { expiry: newExpiryTime, accountId: accountsPayload.id }, accountId: accountsPayload.id }).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setIsModalLoader(true)
        getAccountById(accountsPayload.id).then(res => {
          if (!res.error) {
            setAccountsPayload(res?.data?.data)
            setIsModalLoader(false)
          } else {
            setIsModalLoader(false)
          }
        })
        setButtonLoader({ ...buttonLoader, generate: false })
        getAllData(filterPayload, false);
        setNewExpiryTime(null)
      } else {
        setButtonLoader({ ...buttonLoader, generate: false })
      }
    })

  }

  const handleKeyRotate = () => {
    setButtonLoader({ ...buttonLoader, rotate: true })
    rotateKey({ payload: { expiry: expiry, accountId: accountsPayload.id }, accountId: accountsPayload.id }).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setIsModalLoader(true)
        getAccountById(accountsPayload.id).then(res => {
          if (!res.error) {
            setAccountsPayload(res?.data?.data)
            setIsModalLoader(false)
          } else {
            setIsModalLoader(false)
          }
        })
        getAllData(filterPayload, false);
        setButtonLoader({ ...buttonLoader, rotate: false })
      } else {
        setButtonLoader({ ...buttonLoader, rotate: false })

      }
    })
  }

  const handleSuspendAccount = () => {

    setButtonLoader({ ...buttonLoader, suspend: true })
    suspendAccount(accountsPayload.id).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setIsModalLoader(true)
        getAccountById(accountsPayload.id).then(res => {
          if (!res.error) {
            setAccountsPayload(res?.data?.data)
            setIsModalLoader(false)
          } else {
            setIsModalLoader(false)
          }
        })
        setButtonLoader({ ...buttonLoader, suspend: false })
        getAllData(filterPayload, false);
      } else {
        setButtonLoader({ ...buttonLoader, suspend: false })
      }
    })
  }

  const handleDeleteSecretKey = () => {
    setButtonLoader({ ...buttonLoader, delete: true })
    deleteSecretKey(accountsPayload.id).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setIsModalLoader(true)
        getAccountById(accountsPayload.id).then(res => {
          if (!res.error) {
            setAccountsPayload(res?.data?.data)
            setIsModalLoader(false)
          } else {
            setIsModalLoader(false)
          }
        })
        setButtonLoader({ ...buttonLoader, delete: false })
        getAllData(filterPayload, false);
      } else {
        setButtonLoader({ ...buttonLoader, delete: false })
      }
    })
  }

  const handleRenewAccount = () => {
    setButtonLoader({ ...buttonLoader, renew: true })
    renewAccount({ payload: { expiry: newExpiryTime, accountId: accountsPayload.id }, accountId: accountsPayload.id }).then(res => {
      if (!res.error) {
        toast("Request Completed Successfully", {
          type: "success"
        })
        setIsModalLoader(true)
        getAccountById(accountsPayload.id).then(res => {
          if (!res.error) {
            setAccountsPayload(res?.data?.data)
            setIsModalLoader(false)
          } else {
            setIsModalLoader(false)
          }
        })
        setButtonLoader({ ...buttonLoader, renew: false })
        getAllData(filterPayload, false);
      } else {
        setButtonLoader({ ...buttonLoader, renew: false })
      }
    })

  }

  function handleCopy() {
    navigator.clipboard.writeText(accountsPayload.id);
    setIsTextCopied(true)
  }

  const handleDeleteModalInput = (event) => {
    const { value } = event.target
    if (value === "Delete") {
      setIsDeleteButtonEnabled(true)
    } else {
      setIsDeleteButtonEnabled(false)
    }
  }

  return (
    <React.Fragment>
      <Header83 />
      <div className="content-body83">
        <div className='d-flex-row'>
          <div className='flex-20 padding-x-12'>
            <Form83 onFormChange={(formData) => handleFormDataChange(formData)} submitHandler={handleSubmit} formFields={CustomersForm} submitButtonIcon={""} />
          </div>
          <div className='flex-80 padding-x-12'>
            <div className="table-inner-wrapper table83-bg">
              {
                <Table83
                  hideGoToPage={false}
                  defaultPageSize={10}
                  NoDataFoundComponent={<NoDataFound83 />}
                  pageCount={Math.ceil(data?.data?.count / 10)}
                  initialState={{ pageSize: 10, pageIndex: 0 }}
                  headers={getColumns()}
                  SSRData={Boolean(isSuccess) ? tableData : apiResponse?.response || []}
                  totalRecords={data?.data?.count}
                  isSSR={true}
                  isFiltering={true}
                  preventFirstCall={true}
                  isSelectable={false}
                  onFilteredChange={onFilteredChangeHandler}
                  isPagination={true}
                  isDebounce={true}
                  debounceDuration={100}
                  onPageChange={handlePagination}
                  loading={(isLoading && Boolean(!(apiResponse?.response))) || isFetching}
                  LoadingComponent={<Loader83 />}
                />}
            </div>
          </div>
          {
            <Modal83
              additionalHeading={handleLicenseStatus(editPayload.active, editPayload.suspended, editPayload.expiry, "label")}
              modalWidth={"modal-sm"}
              isVisible={openModal}
              customclass='editModal'
              modalHeading={"Update Account Details"}
              onCancel={cancelButtonHandler}
              onSubmit={editPayloadHandler}>
              <Form83 formData={editPayload} onFormChange={(formData) => handleEditPayloadChange(formData)} submitHandler={editPayloadHandler} formFields={editCustomerForm}>
              </Form83>
            </Modal83>
          }
          {<Modal83
            modalType={"delete"}
            isVisible={isDeleteModal}
            modalHeading={'Delete Account'}
            onCancel={deleteCancelButtonHandler}
            onSubmit={deleteModalHandler}
            deleteItemName={accountName}
            isDeleteButtonDisabled={!isDeleteButtonEnabled}
            onDeleteInputChange={(event) => handleDeleteModalInput(event)}
          >
          </Modal83>}
          {<Modal83 customclass="custom-overflow"
            modalWidth={"modal-md"}
            isVisible={isAccountModal}
            modalHeading={"Account"}
            onCancel={() => { setIsAccountModal(false); setNewExpiryTime(null) }}
            additionalHeading={handleLicenseStatus(active, suspended, expiry, "label")}
            isDeleteButtonDisabled={!isDeleteButtonEnabled}
            hideSubmitButton
          >
            <div className='nested-card83 padding-0'>
              {isModalLoader ? <Loader83 />
                : <div className='nested-card83-body padding-0'>
                  <div className='display-flex flex-wrap align-items-center justify-content-between'>
                    <div className='flex-33 margin-bottom-30'>
                      <h6>Customer Name</h6>
                      <p>{customerName}</p>
                    </div>

                    <div className='flex-33 margin-bottom-30'>
                      <h6>Account Name</h6>
                      <p>{accountsPayload.accountName}</p>
                    </div>

                    <div className='flex-33 margin-bottom-30'>
                      <h6>Environment</h6>
                      <p>{environment}</p>
                    </div>

                    <div className='flex-33'>
                      <h6>Created At</h6>
                      <p>{convertTimestampDateTime(createdAt)}</p>
                    </div>

                    <div className='flex-33'>
                      <h6>Created By</h6>
                      <p>{createdBy}</p>
                    </div>

                    <div className='flex-33'>
                      {expiry ? <><h6>Expiring in</h6>
                        <p className={expiry < Date.now() + 604800000 ? 'text-danger' : 'text-success'}>{timeStampDifference(expiry)}</p></> : ""}
                    </div>
                  </div>
                  <div>
                    <ul className='display-flex flex-wrap align-items-center keys-list'>
                      <li className='flex-33'>
                        <h6>Access Key</h6>
                        <p className='display-flex align-items-center'>
                          <Button83 onClick={handleCopy} className="button83-standard" icon={isTextCopied ? "fas fa-copy" : "far fa-copy"} dataTip={isTextCopied ? "Copied" : "Copy"} />
                          <span style={{ display: "inline-block", width: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className='text-secondary align-items-center'>{accountsPayload?.id}</span>
                        </p>
                      </li>
                      {(planeSecret || accountsPayload.planeSecretKey) && <li className='flex-33'>
                        <h6>Secret Key</h6>
                        <p className='display-flex align-items-center'>
                          <span style={{ display: "inline-block", width: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className='text-secondary align-items-center'>{planeSecret || accountsPayload.planeSecretKey}</span>
                        </p>
                      </li>}
                      {(expiry === 0 || expiry < Date.now() + 604800000) && <li className='flex-33'>
                        <>
                          <h6>Extend Expiry</h6>
                          <select className="form83-control" classnameprefixix="form-control-multi-select" onChange={handleExpirySelect}>
                            <option disabled selected>Select</option>
                            <option value={1296000000}>15 days</option>
                            <option value={2592000000}>30 days</option>
                            <option value={15820800000}>6 months</option>
                            <option value={31536000000}>1 year</option>
                          </select>
                        </>
                      </li>}
                    </ul>
                  </div>

                  <div className='display-flex align-items-center justify-content-between margin-top-30'>
                    {expiry === 0 &&
                      <div className='flex-25'>
                        <Button83 disabled={newExpiryTime === null} onClick={handleKeyGeneration} className=' button83-outlined-primary' label="Generate Key" icon={buttonLoader.generate ? "far fa-spinner fa-spin" : ""} />
                      </div>}
                    {expiry !== 0 &&
                      <>
                        {!suspended && <><div className='flex-25'>
                          <Button83 onClick={handleKeyRotate} className=' button83-outlined-secondary' icon={buttonLoader.rotate ? "far fa-spinner fa-spin" : ""} label="Rotate Key" />
                        </div>
                          <div className='flex-25'>
                            <Button83 onClick={handleSuspendAccount} className=' button83-outlined-warning' icon={buttonLoader.suspend ? "far fa-spinner fa-spin" : ""} label="Suspend Key" />
                          </div> </>}
                        {(expiry < Date.now() + 604800000 || suspended) && <div className='flex-25'>
                          <Button83 disabled={expiry < Date.now() + 604800000 || !suspended} onClick={handleRenewAccount} className=' button83-outlined-primary' icon={buttonLoader.renew ? "far fa-spinner fa-spin" : ""} label="Renew Key" />
                        </div>}
                        <div className='flex-25'>
                          <Button83 onClick={handleDeleteSecretKey} className=' button83-outlined-danger' icon={buttonLoader.delete ? "far fa-spinner fa-spin" : ""} label="Delete Key" />
                        </div>
                      </>
                    }
                  </div>

                </div>}
            </div>
          </Modal83>}
        </div>
      </div>

      <Footer83 />
      <ToastContainer position="top-right" newestOnTop />
    </React.Fragment>
  );
}

export default Customers;